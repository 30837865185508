<template>
    <li v-if="items" class="expand-menu">
        <MegaMenuExpandColumn class="categories-base" :items="items" :maxLevels="4"></MegaMenuExpandColumn>
    </li>
</template>

<script>
    import MegaMenuExpandColumn from "~/components/MegaMenu/MegaMenuExpandColumn.vue";
    export default {
        components: {
            MegaMenuExpandColumn
        },
        props: {
            items: {
                type: Array,
                default: () => []
            }
        }
    };
</script>
