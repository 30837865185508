<template v-if="menu.length || menu_showcase.length">
    <div :class="menu_type === 'expanded' ? 'megamenu-expand' : 'megamenu'" id="menu">
        <b-container>
            <b-navbar>
                <b-navbar-nav class="navbar">
                    <b-nav-item-dropdown no-caret class="all-categories" v-if="noShowcaseItems.length">
                        <template #button-content>
                            <svg class="icon-categories" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="20.007" height="14" viewBox="0 0 20.007 14">
                                <defs>
                                    <clipPath>
                                        <path d="M19.1,14H17.445a.952.952,0,0,1,0-1.9H19.1a.952.952,0,0,1,0,1.9Zm-4.961,0H.909a.952.952,0,0,1,0-1.9H14.137a.952.952,0,0,1,0,1.9ZM19.1,7.951H5.87a.951.951,0,0,1,0-1.9H19.1a.951.951,0,0,1,0,1.9Zm-16.535,0H.909a.951.951,0,0,1,0-1.9H2.563a.951.951,0,0,1,0,1.9ZM19.1,1.9H17.445a.932.932,0,0,1-.91-.95.932.932,0,0,1,.91-.95H19.1a.932.932,0,0,1,.909.95A.932.932,0,0,1,19.1,1.9Zm-4.961,0H.909A.932.932,0,0,1,0,.95.932.932,0,0,1,.909,0H14.137a.932.932,0,0,1,.91.95A.932.932,0,0,1,14.137,1.9Z" transform="translate(0 0)"/>
                                    </clipPath>
                                </defs>
                                <g>
                                    <path d="M19.1,14H17.445a.952.952,0,0,1,0-1.9H19.1a.952.952,0,0,1,0,1.9Zm-4.961,0H.909a.952.952,0,0,1,0-1.9H14.137a.952.952,0,0,1,0,1.9ZM19.1,7.951H5.87a.951.951,0,0,1,0-1.9H19.1a.951.951,0,0,1,0,1.9Zm-16.535,0H.909a.951.951,0,0,1,0-1.9H2.563a.951.951,0,0,1,0,1.9ZM19.1,1.9H17.445a.932.932,0,0,1-.91-.95.932.932,0,0,1,.91-.95H19.1a.932.932,0,0,1,.909.95A.932.932,0,0,1,19.1,1.9Zm-4.961,0H.909A.932.932,0,0,1,0,.95.932.932,0,0,1,.909,0H14.137a.932.932,0,0,1,.91.95A.932.932,0,0,1,14.137,1.9Z" transform="translate(0 0)"/>
                                </g>
                            </svg>
                            <span class="ml-1">CATEGORIAS</span>
                        </template>
                        <component :is="menu_type === 'expanded' ? 'MegaMenuExpand' :  'MegaMenuSide'" :items="noShowcaseItems"/>
                    </b-nav-item-dropdown>

                    <template v-for="item in showcaseItems">
                        <b-nav-item-dropdown no-caret v-if="item.subitems.length && menu_type !== 'expanded'" :text="item.name" @show="$router.push('/categoria/' + item.alias)">
                            <MegaMenuViewCategory :item="item" tag="li"/>
                        </b-nav-item-dropdown>
                        <b-nav-item v-else :class="{'expand': menu_type === 'expanded' }" :to="'/categoria/' + item.alias">
                            {{ item.name }}
                        </b-nav-item>
                    </template>
                    <template v-if="typeof $store.state.menu_site === 'object' && $store.state.menu_site.length">
                        <template v-for="item in $store.state.menu_site">
                            <b-nav-item-dropdown no-caret v-if="item.subitems.length && menu_type !== 'expanded'" :text="item.name" @show="$router.push(item.href)">
                                <MegaMenuView :item="item" tag="li" v-if="item.subitems.length"/>
                            </b-nav-item-dropdown>
                            <b-nav-item v-else :to="item.href">
                                {{ item.name }}
                            </b-nav-item>
                        </template>
                    </template>
                    <template v-if="typeof menu_showcase === 'object' && menu_showcase.length">
                        <b-nav-item class="showcase-link" :to="'/lp/' + item.alias" v-for="item in menu_showcase" :key="item.id">
                            {{ item.name }}
                        </b-nav-item>
                    </template>
                </b-navbar-nav>
            </b-navbar>
        </b-container>
    </div>
</template>

<script>
    import categories from '~/mixins/categories'
    import MegaMenuView from "~/components/MegaMenu/MegaMenuView";
    import MegaMenuSide from "~/components/MegaMenu/MegaMenuSide";
    import MegaMenuViewCategory from "~/components/MegaMenu/MegaMenuViewCategory";
    import MegaMenuExpand from '~/components/MegaMenu/MegaMenuExpand.vue';

    export default {
        mixins: [
            categories
        ],
        components: {
            MegaMenuViewCategory,
            MegaMenuSide,
            MegaMenuView,
            MegaMenuExpand
        },
        data() {
            return {
                menu: [],
                menu_showcase: [],
                menu_type: null,
                showcase: {},
                isShowcase: 'yes'
            }
        },
        methods: {
            itemsFilter(array, prop, value) {
                return array.filter(el => {
                    return el[prop] === value
                })
            }
        },
        computed: {
            showcaseItems() {
                return this.itemsFilter(this.menu, 'showcase', 'yes')
            },
            noShowcaseItems() {
                return this.itemsFilter(this.menu, 'showcase', 'no')
            }
        },
        mounted() {
            if (process.client) {
                this.menu_type = this.$wdconfig.modules.menu?.menu_type || null;
            }
        },
        async fetch() {
            this.menu = JSON.parse(JSON.stringify(this.$store.state.categories))
            this.menu_showcase = this.$store.state.menu_showcase
        },
        watch: {
            '$route.path'(newval, oldval) {
                const boxes = document.getElementById('menu').getElementsByClassName('dropdown-menu');
                for (const box of boxes) {
                    box.classList.add('d-none');
                }
                setTimeout(() => {
                    for (const box of boxes) {
                        box.classList.remove('d-none');
                    }
                }, 50)
            }
        }
    }
</script>
