<template>
    <div @mouseleave="changeHoverCategory({})" ref="container">
        <div class="scroll-arrow up d-flex justify-content-center align-items-center p-2" @mouseenter="scrollCategories('up')" @mouseleave="clearScrollInterval()" v-if="scrollUp">
            <svg width="22px" height="13px" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22 22">
                <g transform="rotate(180 11 11)">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M6.29289 8.79289C6.68342 8.40237 7.31658 8.40237 7.70711 8.79289L12 13.0858L16.2929 8.79289C16.6834 8.40237 17.3166 8.40237 17.7071 8.79289C18.0976 9.18342 18.0976 9.81658 17.7071 10.2071L12.7071 15.2071C12.3166 15.5976 11.6834 15.5976 11.2929 15.2071L6.29289 10.2071C5.90237 9.81658 5.90237 9.18342 6.29289 8.79289Z"/>
                </g>
            </svg>
        </div>
        <div class="megamenu-view-list scroll" ref="scroll" @scroll="checkVisibility">
            <div class="cl-item" v-for="item in items" :key="item.code" @mouseover="changeHoverCategory(item);" @mouseleave="cancelChangeHoverCategory" :class="{'active': category.code === item.code && item.subitems && item.subitems.length}">
                <nuxt-link :to="item.route" class="cate-name d-flex justify-content-between align-items-center">
                    {{ truncate(item.name, 32) }}
                    <div v-if="item.subitems && item.subitems.length && maxLevels > level" class="arrow-expand">
                        <svg width="18px" heigth="18px" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22 22">
                            <path xmlns="http://www.w3.org/2000/svg" fill-rule="evenodd" clip-rule="evenodd" d="M6.29289 8.79289C6.68342 8.40237 7.31658 8.40237 7.70711 8.79289L12 13.0858L16.2929 8.79289C16.6834 8.40237 17.3166 8.40237 17.7071 8.79289C18.0976 9.18342 18.0976 9.81658 17.7071 10.2071L12.7071 15.2071C12.3166 15.5976 11.6834 15.5976 11.2929 15.2071L6.29289 10.2071C5.90237 9.81658 5.90237 9.18342 6.29289 8.79289Z"/>
                        </svg>
                    </div>
                </nuxt-link>
            </div>
        </div>
        <div class="scroll-arrow down d-flex justify-content-center align-items-center p-2" @mouseenter="scrollCategories('down')" @mouseleave="clearScrollInterval()" v-if="scrollDown">
            <svg width="22px" height="13px" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22 22">
                <g>
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M6.29289 8.79289C6.68342 8.40237 7.31658 8.40237 7.70711 8.79289L12 13.0858L16.2929 8.79289C16.6834 8.40237 17.3166 8.40237 17.7071 8.79289C18.0976 9.18342 18.0976 9.81658 17.7071 10.2071L12.7071 15.2071C12.3166 15.5976 11.6834 15.5976 11.2929 15.2071L6.29289 10.2071C5.90237 9.81658 5.90237 9.18342 6.29289 8.79289Z"/>
                </g>
            </svg>
        </div>
        <MegaMenuExpandColumn class="categories-base megamenu-view d-block" v-if="'subitems' in category && category.subitems.length && maxLevels > level" :key="category.code" :items="category.subitems" :maxLevels="maxLevels" :level="level+1"></MegaMenuExpandColumn>
    </div>
</template>

<script>
import MegaMenuExpandColumn from "~/components/MegaMenu/MegaMenuExpandColumn";
import utils from "~/mixins/utils";

export default {
    name: "MegaMenuExpandColumn",
    components: {
        MegaMenuExpandColumn
    },
    mixins: [
        utils
    ],
    props: {
        items: {
            type: Array,
            default: () => []
        },
        maxLevels: {
            type: Number,
            default: 1
        },
        level: {
            type: Number,
            default: 1
        }
    },
    data() {
        return {
            category: {},
            arrowScrollInterval: null,
            scrollUp: false,
            scrollDown: false,
            observer: null,
            categoryTimer: null
        };
    },
    mounted() {
        this.setMaxHeigthOnScreenSize();
        $(window).resize(this.setMaxHeigthOnScreenSize);
        this.checkVisibility();

        this.observer = new IntersectionObserver((entries) => {
            if (entries[0].intersectionRatio > 0) {
                this.checkVisibility();
            }
        });

        if (this.$refs.container) {
            this.observer.observe(this.$refs.container);
        }
    },
    beforeDestroy() {
        if (this.observer) {
            this.observer.disconnect();
        }
    },
    methods: {
        checkVisibility() {
            this.$nextTick(() => {
                const items = this.$refs.scroll.querySelectorAll(".cl-item");
                if (!items.length) {
                    return;
                }
                const firstItem = items[0];
                const lastItem = items[items.length - 1];
                const containerRect = this.$refs.scroll.getBoundingClientRect();
                const firstRect = firstItem.getBoundingClientRect();
                const lastRect = lastItem.getBoundingClientRect();
                this.scrollUp = !(firstRect.top >= containerRect.top && firstRect.bottom <= containerRect.bottom);
                this.scrollDown = !(lastRect.top >= containerRect.top && lastRect.bottom <= containerRect.bottom);
            });
        },
        changeHoverCategory(category) {
            this.categoryTimer = setTimeout(() => {
                this.category = category
            }, 250);
        },
        cancelChangeHoverCategory() {
            clearTimeout(this.categoryTimer);
        },
        setMaxHeigthOnScreenSize() {
            const maxHeigth = $(window).height() - 210;
            const menuLayers = document.querySelectorAll('.categories-base')
            menuLayers.forEach(element => {
                element.style.height = `${maxHeigth}px`
            });
        },
        scrollCategories(direction) {
            const firstItem = this.$refs.container.querySelector('.cl-item');
            const itemHeight = firstItem.clientHeight || 30;

            this.arrowScrollInterval = setInterval(() => {
                this.$refs.scroll.scrollTo({
                    top: this.$refs.scroll.scrollTop + (direction === 'down' ? itemHeight : itemHeight * -1),
                    behavior: "smooth"
                });
                if (direction === 'up' && !this.scrollUp) {
                    clearInterval(this.arrowScrollInterval);
                }
                if (direction === 'down' && !this.scrollDown) {
                    clearInterval(this.arrowScrollInterval);
                }
            }, 200);
        },
        clearScrollInterval() {
            clearInterval(this.arrowScrollInterval);
        }
    }
};
</script>
